<template>
    <div class="page-content">
                  <nav class="page-breadcrumb" id="breadcrumb">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item">{{breadcrumbA}}</li>
                      <li class="breadcrumb-item active" aria-current="page">{{breadcrumbB}}</li>
                    </ol>
                  </nav>

                  <div class="row">
                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">
                          <h6 class="card-title"><i class="link-icon" data-feather="box"></i> {{breadcrumbB}}</h6>
                          <p class="card-description">
                           {{descripcion}}
                            </p>

                        </div>  
                      </div>
                    </div>
                  </div>    

                 <lista-carga v-if="triggers.load"></lista-carga>

                  <div class="row" v-else>
                    <div class="col-md-12 grid-margin stretch-card">
                      <div class="card">
                        <div class="card-body">



                         <div class="row mb-3">
                                <div class="col">
                                    <label class="mr-sm-2">Numero de liquidación</label>
                                    <select id='liqui'></select>
                                </div>
                                
                                <div class="col">
                                    <label class="mr-sm-2 w-100" for="">&nbsp;</label>
                                    <button  class="btn btn-md btn-success w-100" @click="obtenerPersonal()">Consultar</button>
                                </div>
                            </div>

                          <div class="table-responsive">
                            <table id="empresas" class="table">
                              <thead>
                                <tr>
                                  
                                  <th>Id</th>
                                  <th>Nombre y Apellido</th>
                                  <th>Categoria</th>
                                  <th>CBU</th>
                                  <th>Importe Neto</th>
                                  <th>AÑO</th>
                                  <th>MES</th>
                                  <th>DIA</th>
                                  <th>CUIL</th>
                                  <th>CONCEPTO</th>
                                  <th>FIRMADO</th>
                                  <th>RECIBO</th>
                                  <th>DESCARGAR</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="personal in personal" v-bind:key="personal">
                                  
                                    <td>{{personal.legajo}}</td>
                                    <td>{{personal.nombre}}</td>
                                    <td>{{personal.categoria}}</td>
                                    <td>{{personal.cbu}}</td>
                                    <td>{{personal.neto}}</td>
                                    <td>{{personal.anio}}</td>
                                    <td>{{personal.mes}}</td>
                                    <td>{{personal.dia}}</td>
                                    <td>{{personal.cuil}}</td>
                                    <td>LIQUIDACIÓN{{personal.mes}}{{personal.anio}}</td>
                                     <td>{{personal.firmado}}</td>
                                    <td><a target="_blank" :href="personal.recibo">VER</a></td>
                                    <td v-if="personal.firmado=='Conforme'"><a target="_blank" :href="personal.download">DESCARGAR</a></td>
                                    <td v-else>-</td>
                                   
                                </tr>
                              </tbody>
                            </table>
                             <div class="alert alert-danger"  role="alert" id="alerta">
                                  Escribe un número de liquidación y consulta.
                                </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                <modal-administrar
                    v-if="modalAdministrar.open" 
                    :empresa="modalOperacionId"
                    :legajoEmpleado="modalAdministrar.legajo"
                    :nombreEmpleado="modalAdministrar.nombre"
                    :apellidoEmpleado="modalAdministrar.apellido"
                    :operacion="modalAdministrar.operacion"
                    :idDistribucion="modalAdministrar.idDistribucion"
                    @cerrar-modal-asignar="refrescarPersonal($event)"
                >
                </modal-administrar>

                <modal-legales
                    v-if="modalLegales.open" 
                    :dniEmpleado="modalLegales.dni"
                    :nombreEmpleado="modalLegales.nombre"
                    :apellidoEmpleado="modalLegales.apellido"
                    :legajoEmpleado="modalLegales.legajo"
                    @cerrar-modal-legales="refrescarPersonal($event)"
                ></modal-legales>

            </div>
     

</template>

<script>

import axios from 'axios';
import $ from 'jquery';
require('datatables.net');
require('datatables.net-buttons/js/buttons.colVis.js')();
require('datatables.net-buttons/js/buttons.html5.js')();
require('datatables.net-buttons/js/buttons.flash.js')();
require('datatables.net-buttons/js/buttons.print.js')();
import ListaCarga from '../ListaCarga.vue';
import ModalAdministrar from './ModalAdministrar.vue';
import ModalLegales from './ModalLegales';
const userPermission = JSON.parse(sessionStorage.getItem('userPermisos'));
//$("title").html("Liquidacion");
export default {
    name: 'Liquidacion',
    components: {ListaCarga, ModalAdministrar, ModalLegales},
    data() {
        return {
            breadcrumbA: 'Inicio',
            breadcrumbB: 'Liquidacion Food Service America',
            descripcion: 'En este modulo usted podra generar archivo csv valido para enviar al BANCO',
            triggers: {load:true},
            personal: [],
            modalAdministrar: {
                open: false,
                legajo: null,
                nombre: null,
                apellido: null,
                operacion: null,
                idDistribucion: null,
            },
            modalLegales: {
                open: false,
                dni: null,
                nombre: null,
                apellido: null,
            },
        }
    },
    methods: {
        isEditor(modulo){
      
          if (userPermission.find( record => record.ModulosAccess === modulo)) {
            var status =  userPermission.find( record => record.ModulosAccess === modulo).Editor;
            // console.log(modulo+' : '+status)
            return status;
          }else{
            console.log(modulo+' : '+false)
            return false;
          }
        },
        abrirModalAdministrar(legajo,nombre,apellido,operacion,idDistibucion){
            this.modalAdministrar.legajo = legajo;
            this.modalAdministrar.nombre = nombre;
            this.modalAdministrar.apellido = apellido;
            this.modalAdministrar.operacion = (operacion == '-') ? undefined : operacion;
            this.modalAdministrar.idDistribucion = idDistibucion;
            this.modalAdministrar.open = true;
        },
        abrirModalLegales(dni,nombre,apellido,legajo){
            this.modalLegales.dni = dni;
            this.modalLegales.nombre = nombre;
            this.modalLegales.apellido = apellido;
            this.modalLegales.legajo = legajo;
            this.modalLegales.open = true;
        },
        async obtenerPersonal(){



                        let anio = $("#liqui option:selected").data("anio");
                        let mes = $("#liqui option:selected").data("mes");
                        let dia = "06";

                        console.log(dia)
                        console.log(mes)
                        console.log(anio)


            this.triggers.load = true;

            let liquidacion = $("#liqui").val();
            const axiosrequest1 = axios.get('https://auth.foodservice.com.ar/?type=liq_fs_america&liquidacion='+$("#liqui option:selected").val()+'&access_token=1234567');
            
            
            const axiosrequest2 = axios.get('https://aplicativos.firebaseio.com/categorias.json?print=pretty');
        
            

            const axiosrequest3 = axios.get('https://aplicativos.firebaseio.com/recibos/AME/'+$("#liqui option:selected").val()+'.json');

            const axiosrequest4 = axios.get('https://aplicativos.firebaseio.com/liquidacion_recibos/AME.json');
        
            

            await axios.all([axiosrequest1,axiosrequest2,axiosrequest3,axiosrequest4]).then(
            axios.spread((personal, categorias,firmado,liquidaciones) => {
                var lista = [];

                    console.log("busqueda")
                   
                    setTimeout(function(){

                        $.each(liquidaciones.data, function(clave, value) {

                        console.log(value)
                       $("#liqui").prepend("<option data-anio='"+value.anio+"' data-mes='"+value.mes+"' value='"+value.liquidacion+"'>"+value.option+"</option>")
                         })

                    },2000)
                    
                  




              
            const zfill = (number, width) => {
                var numberOutput = Math.abs(number); /* Valor absoluto del número */
                var length = number.toString().length; /* Largo del número */ 
                var zero = "0"; /* String de cero */  
                
                if (width <= length) {
                    if (number < 0) {
                         return ("-" + numberOutput.toString()); 
                    } else {
                         return numberOutput.toString(); 
                    }
                } else {
                    if (number < 0) {
                        return ("-" + (zero.repeat(width - length)) + numberOutput.toString()); 
                    } else {
                        return ((zero.repeat(width - length)) + numberOutput.toString()); 
                    }
                }
            }



                const formatMoney = (number, decPlaces, decSep, thouSep) => {
                    decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces,
                    decSep = typeof decSep === "undefined" ? "," : decSep;
                    thouSep = typeof thouSep === "undefined" ? "." : thouSep;
                    var sign = number < 0 ? "-" : "";
                    var i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
                    var j = (j = i.length) > 3 ? j % 3 : 0;

                    return sign +
                        (j ? i.substr(0, j) + thouSep : "") +
                        i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
                        (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : "");
                }


                const result_categoria = (id) => {
                    var categoria = "";
                    $.each(categorias.data, function(clave, value) {

                        if (value.codigo == id) {
                            categoria = value.nombre;
                        }
                    })
                    return categoria;
                }

              
                const result_firmado = (id) => {
                    var firma = "-";
                    $.each(firmado.data, function(clave, value) {

                        if (clave == id) {
                            
                            if (value.status==1) {
                                firma = "Conforme";
                            }

                             if (value.status==2) {
                                firma = "No conforme";
                            }

                            
                        }
                    })
                    return firma;
                }

                const reemplazarIndefinido = (string) => {
                    if (string==undefined || string == null || string == '') {
                        return "-"
                    }
                    return string
                }


                $.each(personal.data, function(clave, valor) {
                        
                         var tiempo = new Date().getTime().toString();
                         var epoch = tiempo.substring(0, tiempo.length - 3);

                         var recibo = "https://servicio.foodservice.com.ar/mifoodtest/getrcb/?l="+liquidacion+"&d="+valor.datos.dni+"&r=AME&t="+epoch;
                         if (result_firmado(valor.datos.dni)=="Conforme"||result_firmado(valor.datos.dni)=="No conforme") {
                            //recibo = "https://servicio.foodservice.com.ar/core/signa/firmados/"+liquidacion+"-"+valor.datos.dni+".pdf"
                         }

                        lista.push({
                            
                            "legajo": zfill(reemplazarIndefinido(valor.datos.legajo), 18),
                            "categoria": reemplazarIndefinido(result_categoria(valor.datos.cat_tipo)),
                            "nombre": reemplazarIndefinido(valor.datos.nombre),
                            "recibo": recibo,
                            "cbu": reemplazarIndefinido(valor.datos.CBU),
                            "neto": formatMoney(reemplazarIndefinido(valor.datos.neto)),
                            "anio": anio,
                            "mes": mes,
                            "dia": dia,
                            "cuil": reemplazarIndefinido(valor.datos.Cuil),
                            "firmado":result_firmado(valor.datos.dni),
                            "download":'https://servicio.foodservice.com.ar/mifoodtest/getrcb/dwl/?l='+liquidacion+'&d=' + valor.datos.dni + '&t=' + epoch
                        })
                    
                });
                // console.log(lista);
                this.personal = lista;
                this.triggers.load = false;
                })).then(() => {
                $("#alerta").hide();
                    $('#empresas').DataTable({
                        "language": {
                            "decimal": ",",
                            "thousands": ".",
                            "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                            "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                            "infoPostFix": "",
                            "infoFiltered": "(filtrado de un total de _MAX_ registros)",
                            "loadingRecords": "Cargando...",
                            "lengthMenu": "Mostrar _MENU_ registros",
                            "paginate": {
                                "first": "Primero",
                                "last": "Último",
                                "next": "Siguiente",
                                "previous": "Anterior"
                            },
                            "processing": "Procesando...",
                            "search": "Buscar:",
                            "searchPlaceholder": "Término de búsqueda",
                            "zeroRecords": "No se encontraron resultados",
                            "emptyTable": "Ningún dato disponible en esta tabla",
                            "aria": {
                                "sortAscending":  ": Activar para ordenar la columna de manera ascendente",
                                "sortDescending": ": Activar para ordenar la columna de manera descendente"
                            },
                        },
                        "scrollX": false, 
                        "search": {regex:true},
                        dom: 'Bfrtip',
                        responsive: false,
                        nowrap: false,
                        buttons: [
                            'copy',
                            'excel',
                            'csv',
                            'pdf',
                   ]
                    });
                });

                
        },
        refrescarPersonal(seReasigno){
            this.modalAdministrar.open = false;
            this.modalLegales.open = false;
            if (seReasigno) {
                $('#empresas').dataTable().fnDestroy();
                this.obtenerPersonal();
            }

            // this.$nextTick(()=>{
            // })
        },
    },
   async mounted() {
        //this.obtenerPersonal();
         this.triggers.load = false;


            const liquidacion = axios.get('https://aplicativos.firebaseio.com/liquidacion_recibos/AME.json');
        
            

            await axios.all([liquidacion]).then(
            axios.spread((liquidaciones) => {
               



                    $.each(liquidaciones.data, function(clave, value) {
                        console.log(value)
                       $("#liqui").prepend("<option data-anio='"+value.anio+"' data-mes='"+value.mes+"' value='"+value.liquidacion+"'>"+value.option+"</option>")
                    })

})).then(() => {});



    },
};
</script>

<style scoped>
.fila-no-distribuido {
    /* color: red; */
    /* text-shadow: rgba(255, 0, 0, 0.603) 0px 0px 1px; */
    background: radial-gradient(#ff00001a, transparent 91%);
}
</style>